<template>
  <div class="pageContol listWrap templateList templateList1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">消息管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">系统公告</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="标题名称" class="searchboxItem ci-full">
              <span class="itemLabel">标题名称:</span>
              <el-input
                size="small"
                v-model="noticeTitle"
                type="text"
                placeholder="请输入标题名称"
                clearable
              />
            </div>
            <div title="开始时间" class="searchboxItem ci-full">
              <span class="itemLabel">开始时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="startTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
              ></el-date-picker>
            </div>
            <div title="公告类型" class="searchboxItem ci-full">
              <span class="itemLabel">公告类型:</span>
              <el-select v-model="noticeType" placeholder="请选择" size="small" clearable>
                <el-option
                  v-for="item in noticeTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="标题名称" align="left" show-overflow-tooltip prop="noticeTitle" />
              <el-table-column label="公告类型" align="left" show-overflow-tooltip prop="noticeTitle">
                <template slot-scope="scope">
                  {{
                  $setDictionary('NOTICETYPE',scope.row.noticeType || '--')
                  }}
                </template>
              </el-table-column>
              <el-table-column label="发布时间" align="left" show-overflow-tooltip prop="createTime">
                <template slot-scope="scope">
                  {{
                  scope.row.createTime|moment
                  }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="80px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="seeDetails(scope.row.noticeId)"
                  >查看</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "systemNotication",
  components: {
    Empty,
    PageNum
  },
  data() {
    return {
      noticeTitle: "",
      startTime: [],
      noticeTypeList: [], // 公告类型
      noticeType: ""
    };
  },
  mixins: [List],
  created() {
    this.getnoticeify();
  },
  methods: {
    getnoticeify() {
      const list = this.$setDictionary("NOTICETYPE", "list") || [];
      this.noticeTypeList = [];
      for (let key in list) {
        this.noticeTypeList.push({ id: key, name: list[key] });
      }
      console.log( this.noticeTypeList);
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.noticeTitle) {
        params.noticeTitle = this.noticeTitle;
      }
      if (this.noticeType) {
        params.noticeType = this.noticeType;
      }
      if (this.startTime) {
        params.startDateBegin = this.startTime[0];
        params.startDateEnd = this.startTime[1];
      }
      this.doFetch({
        url: "/notice/querySysNoticeCompList",
        params,
        pageNum
      });
    },
    seeDetails(noticeId) {
      this.$router.push({
        path: "/web/noticationDetail",
        query: {
          noticeId,
          needHold2: true
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= +35 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
  beforeRouteLeave: resetKeepAlive
};
</script>

<style lang="less">
// .templateList1 {
//   // .el-cascader {
//   //   height: 32px !important;
//   // }
// }
</style>
